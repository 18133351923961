// Commonly used web colors
$black: #000000;
$white: #ffffff;
$red-600: #b7253d !default; // Deep Carmine
$red-500: #df405a !default; // Cerise
$blurple-600: #a71494;
$blurple-500: #d61ebe;
$blurple-300: #ef6ede;
$grey-600: #4e4c5a; // Trout
$grey-100: #dadaf3; // Topaz

$success-green: #5fe43d !default;
$error-red: #c9343b !default;
$warning-red: #c96932 !default;
$gold-star: #e4ba3d !default;

$red-bookmark: $success-green !default;

// Values from the classic Mastodon UI
$classic-base-color: #190e25;
$classic-primary-color: #d4b6cb;
$classic-secondary-color: #ffe8fc;
$classic-highlight-color: $blurple-500;

// Variables for defaults in UI
$base-shadow-color: $black !default;
$faint-shadow-color: adjust-color($base-shadow-color, $alpha: -0.6);
$base-overlay-background: $black !default;
$base-border-color: $white !default;
$valid-value-color: $success-green !default;
$error-value-color: $error-red !default;

// Tell UI to use selected colors
$ui-base-darker-color: darken($classic-base-color, 5%) !default;
$ui-base-color: $classic-base-color !default;
$ui-base-semi-lighter-color: lighten($ui-base-color, 3%) !default;
$ui-base-lighter-color: lighten($ui-base-color, 10%) !default;
$ui-base-extra-light-color: lighten($ui-base-color, 18%) !default;
$simple-background-color: $ui-base-lighter-color !default;
$ui-primary-color: $classic-primary-color !default;
$ui-secondary-color: $classic-secondary-color !default;
$ui-highlight-color: $classic-highlight-color !default;
$ui-button-color: #ffe9fc;
$ui-button-background-color: $blurple-500;
$ui-button-focus-background-color: $blurple-300;
$ui-button-disabled-background-color: #a686a2;

$ui-button-secondary-color: $blurple-500 !default;
$ui-button-secondary-border-color: $blurple-500 !default;
$ui-button-secondary-focus-background-color: $blurple-600 !default;
$ui-button-secondary-focus-color: $ui-button-color !default;

$ui-button-tertiary-color: #d0adf6 !default;
$ui-button-tertiary-border-color: #d0adf6 !default;
$ui-button-tertiary-focus-background-color: #674e83 !default;
$ui-button-tertiary-focus-color: $white !default;

$ui-button-destructive-background-color: $red-600 !default;
$ui-button-destructive-focus-background-color: $red-500 !default;

// Variables for texts
$primary-text-color: $classic-secondary-color !default;
$darker-text-color: lighten(#847198, 2%) !default;
$dark-text-color: $darker-text-color !default;
$secondary-text-color: $ui-secondary-color !default;
$highlight-text-color: lighten($ui-highlight-color, 8%) !default;
$action-button-color: $dark-text-color !default;
$passive-text-color: $gold-star !default;
$active-passive-text-color: $success-green !default;

// For texts on inverted backgrounds
$inverted-text-color: $primary-text-color !default; // we don't do inverted backgrounds
$lighter-text-color: lighten($darker-text-color, 5%) !default;
$light-text-color: $ui-primary-color !default;

// Variables for components
$media-modal-media-max-width: 100%;

// put margins on top and bottom of image to avoid the screen covered by image.
$media-modal-media-max-height: 80%;

$border-radius: 8px;

body {
  --dropdown-background-color: #{$ui-base-lighter-color};
  --modal-background-color: #{$ui-base-color};
  --modal-background-variant-color: #{$ui-base-semi-lighter-color};
  --background-border-color: #{lighten($ui-base-color, 12%)};
  --background-color: #{$ui-base-color};
  --background-color-tint: #{$ui-base-color};
  --media-outline-color: #{rgba(#9c63c7, 0.15)};
  --toot-focus-background-color: #{color-mix(
      in srgb,
      var(--background-color),
      $ui-highlight-color 5%
    )};
  --toot-private-background-color: #{color-mix(
      in srgb,
      var(--background-color),
      $ui-highlight-color 5%
    )};
  --toot-private-background-focus-color: #{color-mix(
      in srgb,
      var(--background-color),
      $ui-highlight-color 10%
    )};
}
