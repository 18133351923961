// turn the timed line into a highly addictive dopamine slot machine

// fav button
.no-reduce-motion .icon-button.star-icon {
  &.deactivate {
    & > .icon,
    & > .fa-star {
      animation: none;
    }
  }

  &.activate {
    & > .icon,
    & > .fa-star {
      animation: spring-rotate-in 1s cubic-bezier(0.2, 0, 0.4, 1);
    }
  }
}
@keyframes spring-rotate-in {
  0% {
    transform: rotate(0deg);
  }

  20% {
    transform: rotate(20deg);
  }

  60% {
    transform: rotate(-380deg);
  }

  85% {
    transform: rotate(-355deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}
@keyframes spring-rotate-out {
  0% {
    transform: rotate(-360deg);
  }

  60% {
    transform: rotate(20deg);
  }

  85% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

// bookmark button
.no-reduce-motion .icon-button {
  & > .fa-bookmark {
    transform: translateY(0);
    animation: none;
  }

  &.active > .fa-bookmark {
    animation: bookmark-save 0.6s cubic-bezier(0.2, 0, 0.4, 1);
  }
}
@keyframes bookmark-save {
  0% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-3px);
  }

  60% {
    transform: translateY(4px);
  }

  100% {
    transform: translateY(0);
  }
}

// collapse button at the top right of all posts
.no-reduce-motion .status__collapse-button {
  &.activate > .fa-angle-double-up {
    animation: spring-flip-in 0.5s cubic-bezier(0.2, 0, 0.4, 1);
  }

  &.deactivate > .fa-angle-double-up {
    animation: spring-flip-out 0.5s cubic-bezier(0.2, 0, 0.4, 1);
  }
}
@keyframes spring-flip-in {
  0% {
    transform: rotate(0deg);
  }

  60% {
    transform: rotate(-190deg);
  }

  85% {
    transform: rotate(-175deg);
  }

  100% {
    transform: rotate(-180deg);
  }
}
@keyframes spring-flip-out {
  0% {
    transform: rotate(-180deg);
  }

  60% {
    transform: rotate(10deg);
  }

  85% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
